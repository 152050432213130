import React, { Component } from "react";
import stripeLogo from "../img/stripe_logo.png";

import StripeForm from "./StripeForm.js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = fetch(`/.netlify/functions/fetch-client`, { method: "POST" })
  .then((resp) => resp.json())
  .then(({ publish_key }) => loadStripe(publish_key));

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      currency: "USD",
      amount: 0,
      paymentIntent: null,
      error: null
    };

    this.isValidEmail = this.isValidEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }

  isValidEmail() {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email);
  }

  handlePaymentIntent(event) {
    event.preventDefault();

    const { email, amount, currency } = this.state;
    const name = [this.state.firstName, this.state.lastName]
      .filter((s) => s)
      .join(" ");
    fetch(`/.netlify/functions/create-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount, currency, name, email }),
    })
      .then((resp) => {
        if(resp.ok) {
          return resp.json();
        } else {
          return Promise.reject(resp);
        }
      })
      .then((paymentIntent) => {
        this.setState({
          paymentIntent: paymentIntent,
        });
      })
      .catch((error) => {
        error.json().then(({error}) => {
          this.setState({error, payment: null})
        })
      });
  }

  handlePaymentChange(paymentIntent) {
    this.setState({ paymentIntent });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "radio" ? target.id : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: null
    });
  }
  
  render() {
    const { error, paymentIntent } = this.state;
    const status = paymentIntent ? paymentIntent.status : null;
    const errorMsg = error ? error.raw.message : "";
    return (
      <div className="form">
        {!status && (
          <form>
            <div className="form__section-wrapper">
              <div className="form__section form__section--margin-right">
                <div className="form__description">Personal data</div>
                <input
                  className="form__input-text"
                  name="firstName"
                  type="text"
                  placeholder="Name"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                />
                <input
                  className="form__input-text"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                />
                <input
                  className="form__input-text"
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form__section">
                <div className="form__description">Currency</div>
                <div className="form__section-inner">
                  <div>
                    <input
                      className="form__input-radio"
                      name="currency"
                      id="USD"
                      type="radio"
                      checked={this.state.currency === "USD"}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="USD">USD</label>
                  </div>
                  <div>
                    <input
                      className="form__input-radio"
                      name="currency"
                      id="UAH"
                      type="radio"
                      checked={this.state.currency === "UAH"}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="UAH">UAH</label>
                  </div>
                </div>
                <input
                  className="form__input-sum"
                  name="amount"
                  type="number"
                  placeholder="Enter the amount of the donation"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            {errorMsg && 
            <div className="form__section-wrapper">
              <div className="section__error">
                {errorMsg}
              </div>
            </div>}
            <div className="form__section-wrapper">
              <div className="form__section">
                {/* <div className="form__description">Payment method</div> */}
                <div className="form__section-inner">
                  {/* <div>
                  <input
                    className='form__input-payment'
                    name='wayOfPayment'
                    id='payPal'
                    type='radio'
                    onChange={this.handleInputChange} />
                  <label htmlFor='payPal' className='form__payment-method-btn'>
                    <img src={paypalLogo} className='img-paypal' alt='paypal' />
                  </label>
                </div> */}
                  <div>
                    <button
                      className="btn section__btn section__btn--pay"
                      name="wayOfPayment"
                      id="stripe"
                      disabled={!this.state.amount || !this.isValidEmail()}
                      onClick={this.handlePaymentIntent}
                    >
                      Proceed
                      {/* <img
                        src={stripeLogo}
                        className="img-stripe"
                        alt="stripe"
                      /> */}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form__section form__section--margin-right"></div>
            </div>
          </form>
        )}
        {status === "requires_payment_method" && (
          <div className="stripe-form">
            <StripeForm
              onPaymentChange={this.handlePaymentChange}
              stripePromise={stripePromise}
              paymentIntent={this.state.paymentIntent}
            />
          </div>
        )}
        {status === "succeeded" && <div className="form__success">Your payment was successfull! Thank you!</div>}
      </div>
    );
  }
}

export default PaymentForm;
