import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Fragment, useState } from "react";

const CheckoutForm = ({ onPaymentChange }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [isReady, setReady] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log(`Stripe not loaded...`);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://localhost:8888/done",
      },
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setError(result.error.message);
    } else {
      onPaymentChange(result.paymentIntent);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  const handleCancel = () => onPaymentChange(null);

  return (
    <Fragment>
      {!isReady && "Loading.."}
      <form onSubmit={handleSubmit} onCancel={handleCancel}>
        <PaymentElement onReady={() => setReady(true)} />
        {error && (
          <div className="section__error">
            {error}
          </div>
        )}
        <button className="btn section__btn section__btn--small" onClick={handleSubmit}>Submit</button>
        <button className="btn section__btn--white" onClick={handleCancel}>Cancel</button>
      </form>
    </Fragment>
  );
};

const StripeForm = ({ onPaymentChange, stripePromise, paymentIntent }) => {
  const options = {
    clientSecret: paymentIntent.client_secret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm onPaymentChange={onPaymentChange} />
    </Elements>
  );
};

export default StripeForm;
