import React, { Component } from 'react';
import logo from '../img/logo.svg';

export class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'main' // main, aboutUs, howWeHelp, makePayment
    };

    this.handleItemChange = this.handleItemChange.bind(this);
  }

  handleItemChange(event) {
    console.log(event.target)
    const targetId = event.target.id;
    this.setState({
      activeItem: targetId
    })
  };

  render() {
    return(
      <div className='header__menu'>
        <a 
          className={`header__menu-item ${this.state.activeItem === 'main' ? 'header__menu-item--active' : ''}`}
          onClick={this.handleItemChange}
          id='main'
          href='#sectionMain'>Main</a>
        <a 
          className={`header__menu-item ${this.state.activeItem === 'aboutUs' ? 'header__menu-item--active' : ''}`}
          onClick={this.handleItemChange}
          id='aboutUs'
          href='#sectionAboutUs'>About us</a>
        <a 
          className={`header__menu-item ${this.state.activeItem === 'howWeHelp' ? 'header__menu-item--active' : ''}`}
          onClick={this.handleItemChange}
          id='howWeHelp'
          href='#sectionHowWeHelp'>How we help</a>
        <a 
          className={`header__menu-item ${this.state.activeItem === 'makePayment' ? 'header__menu-item--active' : ''}`}
          onClick={this.handleItemChange}
          id='makePayment'
          href='#sectionMakePayment'>Make a contribution</a>
      </div>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.onClickOutsideMenu = this.onClickOutsideMenu.bind(this);
  }

  toggleMobileMenu(event) {
    event.preventDefault();
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    })
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideMenu, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideMenu, false);
  }

  onClickOutsideMenu(event) {
    var ignoreClickOnMeElement = document.getElementById('header__menu-wrapper--mobile');
    var isClickInsideElement = ignoreClickOnMeElement ? ignoreClickOnMeElement.contains(event.target) : false;
    if (!isClickInsideElement) {
      this.setState({
        mobileMenuOpen: false
      })
    }
  }

  render() {
    return (
      <div className="header">
        <div className="header__menu-wrapper--mobile" id='header__menu-wrapper--mobile'>
          <div className="header__menu-btn" onClick={this.toggleMobileMenu}>Menu</div>
          {this.state.mobileMenuOpen ? <HeaderMenu /> : null}
        </div>
        <img src={logo} className="header__logo" alt="logo" />
        <div className="header__menu-wrapper--desktop"><HeaderMenu /></div>
        {/* <div className="header__lngs">
          <button className="btn header__lng header__lng--active">UA</button>
          <button className="btn header__lng">ENG</button>
        </div> */}
      </div>
    );
  }
}

export default Header;
