import Header, { HeaderMenu }  from './components/Header.js';
import PaymentForm from "./components/PaymentForm.js";
import money from './img/money.svg';
import vest from './img/vest.svg';
import box from './img/box.svg';
import imgSoldier from './img/soldier.png';
import logo from './img/logo.svg';
import fb from './img/fb.svg';
import inst from './img/inst.svg';
import styles from './App.scss';

function App() {
  return (
    <div className="App">

      <header><Header /></header>

        <div className="section section--main" id="sectionMain">
          <div className="section__inner">
            <div className="section__title section__title--bold">We are raising funds to support Ukrainian soldiers of light</div>
            <div className="section__description">The public organization SHABLYA, in coordination with the Embassy of Ukraine in the United Kingdom and the Headquarters for Support of Refugees, is raising funds for protective equipment for Ukrainians who are now defending their country and the principles of a free world.</div>
            <a className="btn section__btn" href="#sectionMakePayment">Make a contribution</a>
          </div>
          <div className="section__inner section__inner--img-wrapper">
            <img src={imgSoldier} alt="soldier" />
            </div>
        </div>

        <div className="section section--who-we-are" id="sectionAboutUs">
          <div className="section__inner">
            <div className="section__title">Who we are</div>
            <div className="section__description">We are a volunteer organization of the Ukrainian community in London.
Our goal is to help the freedom-loving people of Ukraine in the fight against the Russian aggressor for the peaceful future of their country and European countries.
</div>
            <div className="section__description">Together with the Embassy of Ukraine in the United Kingdom and the Headquarters for the Support of Displaced Persons, we are raising funds for ammunition and special equipment for Ukrainian defenders.</div>
          </div>
        </div>

        {/* <div className="section">
          <div className="section__inner">
            <div className="section__title">Ми зібрали ХХХ ХХХ грн</div>
            <div className="section__description">Залишилося ще ХХХ ХХХ грн</div>
          </div>
          <div className="section__inner">Diagram</div>
        </div> */}
        <div className="section" id="sectionHowWeHelp">
          <div className="section__inner">
            <div className="section__title">How your money transform into help</div>
            <div className="section__list">
              <div className="section__list-item">
                <div className="section__img"><img src={money} alt="money" /></div>
                <div className="section__desctiption">We raise funds</div>
              </div>
              <div className="section__list-item">
                <div className="section__img"><img src={vest} alt="vest" /></div>
                <div className="section__desctiption">We buy protective equipment</div>
              </div>
              <div className="section__list-item">
                <div className="section__img"><img src={box} alt="box" /></div>
                <div className="section__desctiption">We send it to the front</div>
              </div>
            </div>
            <div className="section__list">
            </div>
          </div>
        </div>

        <div className="section section--pay" id="sectionMakePayment">
          <div className="section__title">Join</div>
          <div className="section__description section__description--no-margin">Contribute to the peaceful future of Ukraine and Europe</div>
          <div className="section__title--small">Card payments</div>
          <div className="section__sub">
            <div className="section__inner">
              <PaymentForm />
            </div>
          </div>
          <div className="section__title--small">Bank payments</div>
          <div className="section__sub">
            <div className="section__inner section__inner--half">
              <div className="section__description">USD</div>
              <div className="section__description--small">
                Citibank
                Account number: 66804191
                ABA/Routing Number: 254070116
                Citibank Address: 1775 Pennsylvania Ave NW, Washington DC, 20006
                KSE address: Kyiv School of Economics
                1350 Connecticut Ave NW, Suite 1000,
                Washington, DC 20036,
                P.O. Box 19748
                Purpose of payment: Humanitarian aid to Ukraine
              </div>
            </div>
            <div className="section__inner section__inner--half">
              <div className="section__description">UAH</div>
              <div className="section__description--small">
                OTP Bank JSC
                Charitable Foundation “Kyiv School of Economics”
                IBAN UA133005280000026004201358463
                BIN 300528
                USREOU identification code: 35133288
                Purpose of payment: Humanitarian aid to Ukraine
              </div>
            </div>
          </div>
        </div>

        <div className="section footer">
          <div className="section__inner">
            <img src={logo} className="header__logo" alt="logo" />
          </div>
          <div className="section__inner"><HeaderMenu /></div>
          <div className="section__inner">
          <div className="footer__menu">
            <div className="footer__menu-item footer__menu-item--title">Contacts</div>
            <div className="footer__menu-item">
              Oleg Shaulko
              +447856100693
            </div>
            <div className="footer__menu-item">
              Dmytro Penziev
              +380 93 566 7800
            </div>
            <div className="footer__menu-item">
              Iryna Tkachenko
              +44 7707 226649
            </div>
          </div>
          </div>
          {/* <div className="section__inner">
            <div className="footer__socials">
              <div className="footer__menu-item footer__menu-item--title">Social Media</div>
              <div className="footer__socials-wrapper">
                <a className="footer__social"><img className="footer__social--fb" src={fb} alt="facebook" /></a>
                <a className="footer__social"><img className="footer__social--inst" src={inst} alt="instagram" /></a>
              </div>
            </div>
          </div> */}
        </div>
      
    </div>
  );
}

export default App;
